import { useState } from "react";

import Image from "./Image";

import "./gallery.scss";

const Gallery = ({ images, imagesMobile, hideCounter }) => {
  return <Image images={images} imagesMobile={imagesMobile} hideCounter={hideCounter} />;
};

export default Gallery;
